// Required
@import "../../node_modules/bootstrap/scss/functions";

// override bootstrap defaults
$primary: #ff6319;
$dark-link: #707070;
$background: #f7f7f7;

.btn-primary {
  color: white !important;
}

// Required
@import "../../node_modules/bootstrap/scss/bootstrap";

// end override

body {
  font-family: "Roboto";
  background-color: $background;
}

.a-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-transparent {
  background-color: transparent;
  border: 2px solid $primary;
  color: $primary;
}

.btn-transparent:hover {
  background-color: $primary;
  color: white;
}

// nav styles
nav .selected {
  color: $primary;
  border-bottom: 1px solid $primary;
}

nav a {
  color: $dark-link;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  padding: 0 0.5rem;
  margin-right: 1rem;
}

nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #707070;
  padding: 10px 20px;
}

.nav-link-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

// containers
.loading-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-h-centered-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  padding: 2rem;
  background-color: rgba(255, 68, 0, 0.2);
  border-radius: 7px;
  min-width: 25%;
}

// table styles
tbody tr {
  background-color: white;
}

.MuiTableCell-body {
  border-bottom: 1rem solid #f7f7f7 !important;
}

.MuiTableCell-head {
  border-bottom: 0 !important;
}

.MuiTableCell-head p {
  font-weight: bold;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: 15px;
}

table .btn-light {
  border: 1px solid #202124;
  border-radius: 4px;
  color: #a6adb4;
}

// client info

@mixin mb-0 {
  margin-bottom: 0;
}

@mixin info-container {
  background-color: white;
  border-color: black;
  border: 1px solid black;
  border-radius: 36px;
  padding: 2rem 4rem;
}

.info-container {
  @include info-container;
  margin-top: 3.3rem;
}

.info-container-mt-0 {
  margin-top: 0;
}

// remove margin-bottom from all text elements in client-info
.info-container p,
h1,
h2,
h3,
h4 {
  @include mb-0;
}

.info-container h3 {
  font-size: 25px;
  font-weight: bold;
  position: relative;
}

.info-container p {
  font-size: 18px;
}

.edit-icon:hover {
  opacity: 0.5;
}

.edit-icon:active {
  color: $primary;
}

.client-info .contact {
  margin-top: 10px;
}

.bg-red {
  background-color: red;
}

.hover-icon:hover {
  opacity: 0.5;
}

.hover-icon:active {
  color: $primary;
}

// transaction details styles

.input-title {
  @include mb-0;
  font-weight: bold;
  font-size: 18px;
}

.section-title {
  @include mb-0;
  font-weight: bold;
  font-size: 20px;
}

.input-text {
  @include mb-0;
  font-size: 18px;
}

.white-bg {
  background-color: white;
}

.input-box {
  padding: 10px 10px;
  border: 2px solid #ebeded;
  border-radius: 6px;
}

.no-padding {
  padding: 0;
}

.link {
  color: #72acff;
  cursor: pointer;
}

.drag-box {
  padding: 3rem 0rem;
}

.drag-box-active {
  background-color: #ff621927;
}

$thumb-height: 10rem;

.thumbnail {
  width: 30%;
}

.thumbnail-inner {
  width: 100%;
  height: $thumb-height;
  overflow: hidden;
}

.thumbnail-inner > img {
  width: 100%;
  object-fit: cover;
}

.transaction-info-padder {
  padding: 0 30px;
}

// image collapses
.classified-images {
  background-color: white;
  border-radius: 6px;
  border: 2px solid #ebeded;
  width: 100%;
}

.btn-send {
  background-color: #34c759;
}

// /transaction details styles

.remove-icon {
  color: red;
}

// add button
.add-btn {
  padding: 0;
}

.add-btn > svg {
  color: $primary;
}

.add-btn:hover > svg {
  opacity: 0.5;
}

.add-btn:focus {
  box-shadow: none;
}

.modal-90w {
  max-width: 90%;
}

.hover-primary:hover {
  color: $primary;
  cursor: pointer;
}

// frontpage
.stats-container {
  background-color: white;
  border: 1px solid #15223214;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 20px;
}

// override the gap on the frontpage
.frontpage .MuiTableCell-body {
  border-bottom: 0 !important;
}

.transaction-state {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid black;
}